<template>
  <v-container fill-height class="vh-100" width="100%">
    <v-row justify="center" align="center">
      <v-container>
        <v-row>
          <v-col sm="10" md="8" offset-md="2" offset-sm="1">
            <v-card elevation="4" class="pb-2">
              <app-public-header :showHome="false" :showBack="true" />
              <v-divider></v-divider>
              <v-alert v-if="notification" :type="notificationType" class="ma-4">
                {{ notification }}
              </v-alert>
              <v-card-text v-if="testing && !testedCode" class="text-center">
                <h3>Checking Registration Code</h3>
                <v-progress-circular indeterminate color="primary" size="50" class="mt-6" />
              </v-card-text>
              <v-card-text v-show="!testing && invite">
                <h3 v-if="invite && invite.invitedByFriendlyName && invite.companyName">
                  You have been invited to join Acrisure Safety by:<br />
                  <span class="font-weight-bold">{{ invite.invitedByFriendlyName }}</span>
                  from
                  <span class="font-weight-bold">{{ invite.companyName }}</span>
                </h3>
                <template v-if="invite && invite.note && invite.note !== ''">
                  <h4 class="mt-2">Custom Note:</h4>
                  <p>{{ invite.note }}</p>
                </template>
                <v-form
                  ref="form"
                  class="my-6"
                  v-model="formValid"
                  @submit.prevent="registerUser()"
                  :disabled="registering"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        data-lpignore="true"
                        v-model="fields.email"
                        tabindex="1"
                        type="email"
                        required
                        prepend-icon="mdi-email"
                        :rules="[rules.required, rules.emailValid]"
                        :disabled="registering"
                        ><template #label
                          >Email <span class="required"><strong>*</strong></span></template
                        ></v-text-field
                      >
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="fields.firstName"
                        ref="firstname"
                        maxlength="20"
                        tabindex="2"
                        required
                        prepend-icon="mdi-account-box-outline"
                        :rules="[rules.required]"
                        :disabled="registering"
                        ><template #label
                          >{{ $t("first_name") }} <span class="required"><strong>*</strong></span></template
                        ></v-text-field
                      >
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="fields.lastName"
                        label="Last Name"
                        maxlength="20"
                        required
                        tabindex="3"
                        prepend-icon="mdi-account-box-outline"
                        :rules="[rules.required]"
                        :disabled="registering"
                        ><template #label
                          >Last name <span class="required"><strong>*</strong></span></template
                        ></v-text-field
                      >
                    </v-col>
                    <v-col cols="12">
                      <PhoneInput :tabIndex="4" v-model="fields.phoneNumber"></PhoneInput>
                    </v-col>
                    <v-col cols="6" md="6" sm="12">
                      <v-text-field
                        tabindex="5"
                        data-lpignore="true"
                        v-model="fields.password"
                        name="new-password"
                        hint="At least 8 characters"
                        prepend-icon="mdi-key"
                        counter
                        :rules="[rules.required, rules.min]"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        :type="showPassword ? 'text' : 'password'"
                        :disabled="registering"
                        ><template #label
                          >Password <span class="required"><strong>*</strong></span></template
                        ></v-text-field
                      >
                    </v-col>
                    <v-col cols="6" md="6" sm="12">
                      <v-text-field
                        tabindex="6"
                        data-lpignore="true"
                        v-model="fields.passwordConfirm"
                        name="confirm-password"
                        prepend-icon="mdi-key-plus"
                        counter
                        :rules="[rules.required, rules.min, passwordMatch]"
                        :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPasswordConfirm = !showPasswordConfirm"
                        :type="showPasswordConfirm ? 'text' : 'password'"
                        :disabled="registering"
                        ><template #label
                          >Confirm password <span class="required"><strong>*</strong></span></template
                        ></v-text-field
                      >
                    </v-col>
                    <v-col class="d-flex ml-auto mr-3" cols="12">
                      <v-spacer></v-spacer>
                      <v-btn
                        tabindex="7"
                        large
                        color="primary"
                        type="submit"
                        :disabled="registering"
                        @click="registerUser()"
                      >
                        <v-icon v-if="!registering">mdi-account-plus</v-icon>
                        <span v-if="!registering" class="ml-1">Register</span>
                        <v-progress-circular v-else indeterminate />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import AppPublicHeader from "@/components/AppPublicHeader";
import { UserService } from "@services";
import Helpers from "@/mixins/helpers";
import PhoneInput from "@/components/PhoneInput";

export default {
  name: "RegisterUser",
  metaInfo: {
    title: "Register User",
  },
  components: {
    AppPublicHeader,
    PhoneInput,
  },
  data() {
    return {
      notification: null,
      notificationType: null,
      testing: false,
      testedCode: null,
      invite: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        emailValid: (v) => /.+@.+\..+/.test(v) || "Email must be valid",
        phoneE164: (v) =>
          /^\+[1-9]\d{10,14}$/.test(v) ||
          "Must be in e164 format ( [+] [country code] [subscriber number including area code] ), ie: +18885559999",
      },
      formValid: true,
      fields: {
        languageCode: "en-US", // Hardcoded for now
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        password: null,
        passwordConfirm: null,
      },
      showPassword: false,
      showPasswordConfirm: false,
      registering: false,
    };
  },
  mixins: [Helpers],
  computed: {
    ...mapGetters("settings", ["settings"]),
    ...mapGetters("auth", ["isAuthenticated"]),
    passwordMatch() {
      return () => this.fields.password === this.fields.passwordConfirm || "Passwords must match";
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    testCode() {
      this.testing = true;

      const params = {
        code: this.$route.query.code,
      };

      UserService.getInvitation(params)
        .then((r) => {
          this.invite = r.data;
          this.testedCode = this.$route.query.code;
          this.fields.email = this.invite.sentToEmail;
          let focusTimeout = setTimeout(() => {
            clearTimeout(focusTimeout);
            this.$refs.firstname.focus(); //nextTick didn't delay enough here for some reason, 500ms is the same delay lastpass uses to add its elements to the DOM and it seems to work well here as well
          }, 500);
        })
        .catch((err) => {
          this.notificationType = "error";
          if (err) {
            this.notification = this.getErrorMessage(err);
          } else {
            this.notification = "An unknown error occured!";
          }
        })
        .then(() => {
          this.testing = false;
        });
    },
    registerUser() {
      this.registering = true;
      if (!this.$refs.form.validate()) {
        this.registering = false;
        this.notificationType = "error";
        this.notification = "Please fill out all form fields.";
        return;
      }

      if (this.formValid) {
        const params = {
          code: this.testedCode,
          email: this.fields.email,
          firstName: this.fields.firstName,
          lastName: this.fields.lastName,
          phoneNumber: this.fields.phoneNumber,
          password: this.fields.password,
        };

        UserService.registerUser(params)
          .then(() => {
            this.notificationType = "success";
            this.notification =
              "Your account has been created! You will be redirected to the Sign In page momentarily!";
            let redirectTimeout = setTimeout(() => {
              this.registering = false;
              clearTimeout(redirectTimeout);
              if (this.isAuthenticated) {
                this.logout();
              } else {
                this.$router.push({ path: "/login" });
              }
            }, 3000);
          })
          .catch((err) => {
            this.registering = false;
            this.notificationType = "error";
            if (err) {
              this.notification = this.getErrorMessage(err);
            } else {
              this.notification = "An unknown error occured!";
            }
          });
      }
    },
  },
  mounted() {
    if (this.$route.query.code) {
      this.testCode();
    } else {
      this.notificationType = "error";
      this.notification =
        "No registration code found. Please make sure you use the button or URL inside the email sent to you.";
    }
  },
};
</script>
